




















































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { PollType } from '@/api/interfaces.api';
import TabMenu from './TabMenu.vue';
import draggable from 'vuedraggable';

export default Vue.extend({
  name: 'PollForm',
  components: { TabMenu, draggable },
  props: {
    onSubmit: {
      type: Function,
      required: true,
    },
    isEditMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    pollId: {
      type: String,
      required: false,
      default: '',
    },
    presenterSessionId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    // TODO: Not working on page reload
    if (this.isEditMode && this.poll) {
      this.form = {
        title: this.poll.title,
        answerOptions:
          this.poll.type === PollType.Slider ? this.poll.answerOptions : {},
        defaultValue:
          this.poll.type === PollType.Slider ? this.poll.defaultValue : null,
        type: this.poll.type,
        description:
          this.poll.description && this.poll.description.text
            ? this.poll.description.text
            : '',
        choices:
          this.poll.type === PollType.SingleChoice
            ? this.poll.answerOptions.choices
            : [],
        visible: this.poll.visible ?? false,
        isPaused: this.poll.isPaused ?? false,
      };
    }
  },
  data() {
    return {
      form: {
        title: '',
        answerOptions: { min: null, max: null },
        defaultValue: null,
        type: PollType.SingleChoice,
        choices: [] as any[],
        visible: false,
        isPaused: false,
        description: '',
      },
      selectedCategories: [] as string[],
      drag: false,
      choiceNumber: 0,
    };
  },
  computed: {
    ...mapGetters({
      shareURL: 'presenter/getShareURL',
      getCurrentPoll: 'presenter/getCurrentPoll',
    }),
    poll(): any {
      if (this.pollId) {
        const [poll] = this.getCurrentPoll(this.pollId);
        return poll;
      }
      return null;
    },
    isFormSubmitable(): boolean {
      const choicesState =
        this.form.choices.length > 0 &&
        this.form.choices.every(
          (choice: { id: string; value: string }) =>
            choice.id !== '' && choice.value !== ''
        );
      return (
        this.form.title !== '' &&
        ((!!this.form.answerOptions.min &&
          !!this.form.answerOptions.max &&
          !!this.form.defaultValue) ||
          choicesState)
      );
    },
    tabItems(): any {
      return [
        // {
        //   id: 0,
        //   name: 'Slider',
        //   isSelected: this.form.type == 'SLIDER',
        //   value: 'SLIDER',
        // },
        {
          id: 1,
          name: 'Singlechoice',
          isSelected: this.form.type == 'CHOICE',
          value: 'CHOICE',
        },
      ];
    },
  },
  methods: {
    async submitForm() {
      if (this.isEditMode) {
        await this.$store.dispatch('presenter/updatePoll', {
          form: this.form,
          pollId: this.pollId,
          // pollCategories: this.selectedCategories,
        });
      } else {
        await this.$store.dispatch('presenter/createPoll', {
          form: this.form,
          // pollCategories: this.selectedCategories,
        });
      }

      this.onSubmit();
    },
    selectCategory(categoryId: string) {
      this.selectedCategories = [categoryId];
    },
    onTabMenuSelect(val: PollType) {
      this.form.type = val;
    },
    addChoice() {
      const number = this.choiceNumber;
      this.form.choices.push({ id: `${this.choiceNumber}`, value: '' });
      const refs = this.$refs as any;
      this.$nextTick(() => refs[`choiceInput${number}`][0].focus());
      this.choiceNumber++;
    },
    removeChoice(i: number) {
      this.form.choices.splice(i, 1);
      this.choiceNumber--;
    },
  },
});
